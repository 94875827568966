.header {
  background: #1890ff;
  padding: 0;
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 64px;
}

.logo {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.site-layout-background {
  background: #fff;
}

.welcome-message {
  font-size: 18px;
  color: #888;
  text-align: center;
  padding: 20px;
}
