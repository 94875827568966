.question-content p {
  margin: 0;
  padding: 4px 0;
  text-align: left; /* 文本居左对齐 */
}

.content-table {
  width: auto; /* 表格宽度自适应内容 */
  border-collapse: collapse;
  margin-left: 0; /* 表格居左 */
  table-layout: auto; /* 自动调整列宽 */
}

.content-table th, .content-table td {
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出内容隐藏 */
  text-overflow: ellipsis; /* 内容省略号 */
}

.content-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.question-detail {
  max-width: 800px; /* 设置题目描述的最大宽度 */
  margin: 20px auto; /* 居中显示 */
  padding: 20px; /* 内边距 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
  background-color: #fff; /* 白色背景 */
  border-radius: 8px; /* 圆角 */
  text-align: left; /* 文本居左对齐 */
}

.sql-input-container {
  margin-top: 32px; /* 增大与正文的距离 */
}

.sql-input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.sql-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.submit-button {
  margin-top: 16px;
  width: 100%;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #40a9ff;
}

.result-container {
  display: flex;
  flex-direction: column;
}

.result-section {
  width: 100%;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
}

.result-table th, .result-table td {
  border: 1px solid #ddd;
  padding: 8px;
  white-space: nowrap; /* 禁止换行 */
  overflow: hidden; /* 超出内容隐藏 */
  text-overflow: ellipsis; /* 内容省略号 */
}

.result-table th {
  background-color: #f2f2f2;
  text-align: left;
}
